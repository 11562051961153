import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Instagram } from "@styled-icons/boxicons-logos/Instagram"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const SocialIcon = styled.div`
  position: relative;

  vertical-align: center;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 2.4rem;
`

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <ul className="nav" role="menu">
              <li className="nav-about" role="menuitem">
                <Link to={`/portfolio`}>Portfolio</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/publications`}>Publications</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/about`}>About</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/contact`}>Contact</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <a
                  href="https://www.instagram.com/cdulievre"
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialIcon>
                    <Instagram style={{ height: "24px", width: "24px" }} />
                  </SocialIcon>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        {children}
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()}{" "}
        <Link to={`/`}>Claudia du Lièvre</Link>
      </footer>
    </div>
  )
}

export default Layout
